import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { cloudsAnimationWrapper } from '../../components/elements'

import introDictionaryBG from '../../images/home/home_sky_bg.svg'
import introCloudBG from '../../images/mission/intro_mission_girl.svg'
import introCloudMobileBG from '../../images/mission/intro_mission_girl_mobile.svg'

const IntroMissionWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  background-image: url(${introDictionaryBG});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto;
  margin-bottom: 20px;
  z-index: 10;
  @media (min-width: 768px) and (max-width: 991px) {
    background-position-y: -10%;
    background-size: auto 90%;
  }
  @media (max-width: 767px) {
    /* background-image: none; */
  }
  @media (max-width: 575px) {
    margin-bottom: 20px;
  }
`

const IntroMissionCol = styled(Col)`
  position: relative;
  min-height: 520px;
  text-align: center;
  padding-top: 8.5%;
`

const IntroMissionTitle = styled.h1`
  font-family: ${(props) => props.theme.fontBNPPSans};
  strong {
    display: block;
    color: #060708;
    font-size: 40px;
    font-weight: 400;
    line-height: 1;
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 30px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 26px;
    }
    @media (max-width: 575px) {
      font-size: 24px;
    }
  }
  span {
    display: block;
    color: #060708;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.2;
    padding-top: 15px;
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      /* background-color: ${(props) => props.theme.colorWhite}; */
      padding: 4.5%;
      margin-top: 50px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 17px;
      margin-top: 0;
    }
    @media (max-width: 575px) {
      font-size: 16px;
      padding-top: 5px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

const IntroMissionCloud = styled.p`
  position: absolute;
  top: 10%;
  right: 0;
  width: 180px;
  height: 100%;
  background-image: url(${introCloudBG});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  color: transparent;
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 22px;
  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;
  padding-top: 15px;
  @media (min-width: 768px) and (max-width: 991px) {
    width: 150px;
  }
  @media (max-width: 767px) {
    position: static;
    width: 100%;
    height: 160px;
    background-image: url(${introCloudMobileBG});
    background-position: center;
    background-size: contain;
  }
`

const IntroMissionCloudsAnimationWrapper = styled(cloudsAnimationWrapper)``

const IntroMission = (props) => {
  return (
    <IntroMissionWrapper>
      <IntroMissionCloudsAnimationWrapper>
        <div className="layersWrapper">
          <div className="cloudLayer once cloudLayerOnce1" />
          <div className="cloudLayer once cloudLayerOnce2" />
          <div className="cloudLayer once cloudLayerOnce3" />
          <div className="cloudLayer once cloudLayerOnce4" />
          <div className="cloudLayer cloudLayer1" />
          <div className="cloudLayer cloudLayer2" />
          <div className="cloudLayer cloudLayer3" />
          <div className="cloudLayer cloudLayer4" />
          <div className="cloudLayer cloudLayer5" />
          <div className="cloudLayer cloudLayer6" />
          <div className="cloudLayer cloudLayer7" />
          <div className="cloudLayer cloudLayer8" />
        </div>
      </IntroMissionCloudsAnimationWrapper>
      <Container>
        <Row>
          <IntroMissionCol xs={12}>
            <IntroMissionTitle>
              <strong>
                Zobacz, co przygotowaliśmy
                <br />
                dla Ciebie i Twojego dziecka,
              </strong>
              <span>
                żeby mogło uczyć się samodzielności w świecie finansów.
              </span>
            </IntroMissionTitle>
            <IntroMissionCloud />
          </IntroMissionCol>
        </Row>
      </Container>
    </IntroMissionWrapper>
  )
}

export default IntroMission
