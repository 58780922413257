import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
} from 'reactstrap'

import { Button, buttonStretchedMixin, ListColorDots } from '../elements'
import { NonceImg } from '../img'

import step2MissionCard1Icon from '../../images/mission/step2_mission_card1_icon.svg'
import step2MissionCard2Icon from '../../images/mission/step2_mission_card2_icon.svg'
import step2MissionCard3Icon from '../../images/mission/step2_mission_card3_icon.svg'
import step2MissionPayCard1 from '../../images/mission/step2_mission_pay_card_1.svg'
import step2MissionPayCard2 from '../../images/mission/step2_mission_pay_card_2.svg'
import step2MissionPayCard3 from '../../images/mission/step2_mission_pay_card_3.svg'
import step2MissionDottedWrapperBG from '../../images/mission/step2_mission_dotted_wrapper_bg.svg'
import step2MissionBoyIcon from '../../images/mission/step2_mission_boy.svg'

const Step2MissionWrapper = styled.div`
  position: relative;
  height: 100%;
  margin-bottom: 50px;
  z-index: 10;
  @media (max-width: 575px) {
    margin-bottom: 30px;
  }
`

const Step2MissionColHeader = styled(Col)`
  text-align: center;
  margin-bottom: 15px;
`

const Step2MissionColDesc = styled(Col)`
  padding-top: 40px;
`

const Step2MissionStageTitle = styled.h2`
  display: inline-block;
  background-color: ${(props) => props.theme.colorGreenLight};
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 34px;
  font-weight: 700;
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
`

const Step2MissionSubTitle = styled.p`
  color: ${(props) => props.theme.colorGreenLight};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 50px;
  font-weight: 700;
  line-height: 1;
  @media (max-width: 575px) {
    margin-top: 10px;
    font-size: 36px;
  }
`

const Step2MissionCardItem = styled(Col)`
  position: relative;
  @media (max-width: 767px) {
    display: ${(props) => (props.show === 'true' ? 'block' : 'none')};
  }
`

const Step2MissionCard = styled(Card)`
  display: flex;
  height: 100%;
  align-items: flex-start;
  border: none;
  box-shadow: 0px 12px 25px 0px rgba(24, 11, 11, 0.25);
`

const Step2MissionCardBody = styled(CardBody)`
  @media (max-width: 767px) {
    width: 100%;
  }
`

const Step2MissionCardIcon = styled.div`
  height: 85px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    display: none;
  }
`

const Step2MissionCardSwitcherWrapper = styled.div`
  display: none;
  text-align: center;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }
`

const Step2MissionCardSwitcherIcon = styled.div`
  width: 85px;
  height: 85px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
  margin-bottom: 15px;
  border: 3px solid ${(props) => props.theme.colorGrayLight};
  border-radius: 2px;
`

const Step2MissionCardSwitcherItem = styled.div`
  display: inline-block;
  color: ${(props) => props.theme.colorGreenLight};
  font-size: 19px;
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
  transition: all 0.2s ease;
  cursor: pointer;

  @media (max-width: 374px) {
    transform: scale(0.8);
  }

  @media (max-width: 575px) {
    margin: 0;
    margin-right: 10%;
  }

  &:hover,
  &:focus {
    ${Step2MissionCardSwitcherIcon} {
      border: 3px solid ${(props) => props.theme.colorGreenLight};
    }
    span {
      opacity: 1;
    }
  }
  ${Step2MissionCardSwitcherIcon} {
    ${(props) =>
    props.number === props.shown &&
    `
      border: 3px solid ${props.theme.colorGreenLight};
    `}
  }
  span {
    opacity: 0.65;
    ${(props) =>
    props.number === props.shown &&
    `
      opacity: 1;
    `}
  }
`

const Step2MissionCardTitle = styled(CardTitle)`
  min-height: 60px;
  color: ${(props) => props.theme.colorGreenLight};
  font-size: 32px;
  font-weight: 400;
  padding-left: 20px;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 26px;
  }
  @media (max-width: 575px) {
    min-height: auto;
    font-size: 19px;
    padding-left: 0;
    margin-bottom: 10px;
  }
`

const Step2MissionList = styled(ListColorDots)``

const Step2MissionCardImageWrapper = styled.div`
  align-self: flex-end;
  width: 100%;
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const Step2MissionCardPay = styled.div`
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0;
  height: 200px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translateY(50%);
  z-index: 20;
  ${(props) =>
    props.left &&
    `
    background-position-x: right;
    right: 40px;
  `}
  ${(props) =>
    props.right &&
    `
    background-position-x: left;
    left: 40px;
  `}
  @media (min-width: 768px) and (max-width: 991px) {
    height: 120px;
  }
  @media (max-width: 767px) {
    left: 15px;
    right: 15px;
    background-position-x: center;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    height: 150px;
  }
  @media (max-width: 575px) {
    height: 120px;
  }
`

const Step2MissionColBottom = styled(Col)`
  position: relative;
  /* background-image: url(${step2MissionDottedWrapperBG});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-position-x: calc(100% - 15px);
  background-size: contain; */
  padding-top: 100px;
  z-index: 10;
  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const Step2MissionColButtonWrapper = styled(Col)`
  position: relative;
  text-align: center;
  z-index: 40;
`

const Step2MissionButton = styled(Button)`
  ${buttonStretchedMixin}
  max-width: 370px;
  margin-top: 60px;
  margin-bottom: 98px;

  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  &:after {
    content: none;
  }
`

const Step2MissionColInnerWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colorGrayLight};
  padding-top: 40px;
  padding-left: 65px;
  padding-right: 15px;
  padding-bottom: 40px;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 30px;
    padding-left: 200px;
    padding-bottom: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 30px;
    padding-left: 160px;
    padding-bottom: 30px;
  }
  @media (max-width: 575px) {
    padding-top: 210px;
    padding-left: 15px;
    padding-bottom: 30px;
  }
`

const Step2MissionBottomTitle = styled(Step2MissionCardTitle)`
  min-height: 60px;
  @media (max-width: 575px) {
    min-height: auto;
    font-size: 24px;
  }
`

const Step2MissionAttention = styled(Col)`
  font-weight: 300;
  padding-left: 8%;
  padding-right: 8%;
  margin-top: 70px;
  a {
    color: ${(props) => props.theme.colorGreenLight}!important;
    /* font-family: ${(props) => props.theme.fontBNPPSansCondensed}; */
    font-weight: 700;
  }
`

const Step2MissionAttentionTitle = styled.p`
  color: ${(props) => props.theme.colorGreenLight};
  font-family: ${(props) => props.theme.ontBNPPSansCondensed};
  font-size: 34px;
  font-weight: 700;
`

const Step2MissionNote = styled(Col)`
  /* margin-top: 40px; */
  margin-top: 160px;
`

const Step2MissionNoteWrapper = styled.div`
  background-color: ${(props) => props.theme.colorGrayLight};
  padding-top: 40px;
  padding-left: calc(8% - 15px);
  padding-right: calc(8% - 15px);
  padding-bottom: 30px;
`

const Step2MissionNoteTitle = styled.p`
  color: ${(props) => props.theme.colorGreenLight};
  font-size: 24px;
`

const Step2MissionNoteButtonWrapper = styled(Col)`
  text-align: center;
  padding-bottom: 80px;
`

const Step2MissionNoteButton = styled(Button)`
  max-width: 400px;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  &:after {
    content: none;
  }

  @media (max-width: 575px) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  span {
    @media (max-width: 575px) {
      padding-left: 0;
    }
  }
`

const Step2WatchFeatures = styled(Col)`
  border: 2px dotted #c1c1c1;
  border-radius: 5px;
  margin-top: 150px;
  margin-left: 32%;
  padding: 30px;
  width: 65%;

  @media (max-width: 499px) {
    margin-left: 0;
    width: 100%;
  }

  &:before {
    background-image: url(${step2MissionBoyIcon});
    background-repeat: no-repeat;
    background-position: 0 50%;
    content: '';
    display: block;
    height: 100%;
    left: -45%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;

    @media (max-width: 499px) {
      display: none;
    }

    @media (max-width: 992px) {
      background-size: contain;
    }
  }

  h3 {
    color: ${(props) => props.theme.colorGreenLight};
    margin-bottom: 30px;
    text-transform: uppercase;
  }
`

const Step2Mission = (props) => {
  const data = useStaticQuery(graphql`
    query {
      step2MissionImage1: file(
        relativePath: { eq: "mission/step2_mission_image_1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 555) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      step2MissionImage2: file(
        relativePath: { eq: "mission/step2_mission_image_2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 555) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      step2MissionImage3: file(
        relativePath: { eq: "mission/step2_mission_image_3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 555) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const [showNumberCard, setShowNumberCard] = useState(1)

  const MobileCardsSwicher = () => {
    return (
      <Step2MissionCardSwitcherWrapper>
        <Step2MissionCardSwitcherItem
          number={1}
          shown={showNumberCard}
          onClick={() => setShowNumberCard(1)}
        >
          <Step2MissionCardSwitcherIcon bg={step2MissionCard1Icon} />
          <span>Karta</span>
        </Step2MissionCardSwitcherItem>
        <Step2MissionCardSwitcherItem
          number={2}
          shown={showNumberCard}
          onClick={() => setShowNumberCard(2)}
        >
          <Step2MissionCardSwitcherIcon bg={step2MissionCard2Icon} />
          <span>Opaska</span>
        </Step2MissionCardSwitcherItem>
        {false && (
          <Step2MissionCardSwitcherItem
            number={3}
            shown={showNumberCard}
            onClick={() => setShowNumberCard(3)}
          >
            <Step2MissionCardSwitcherIcon bg={step2MissionCard3Icon} />
            <span>Zegarek</span>
          </Step2MissionCardSwitcherItem>
        )}
      </Step2MissionCardSwitcherWrapper>
    )
  }

  return (
    <Step2MissionWrapper>
      <Container>
        <Row>
          <Step2MissionColHeader>
            <Step2MissionStageTitle>Krok 2</Step2MissionStageTitle>
            <Step2MissionSubTitle>
              Wybierz kartę pomocną
              <br /> w&nbsp;samodzielnym bankowaniu
            </Step2MissionSubTitle>
          </Step2MissionColHeader>
          <Step2MissionColDesc xs={12}>
            <Row>
              <Step2MissionCardItem
                xs={12}
                md={6}
                show={(showNumberCard === 1).toString()}
              >
                <Step2MissionCard>
                  <Step2MissionCardBody>
                    <Step2MissionCardIcon bg={step2MissionCard1Icon} />
                    <MobileCardsSwicher />
                    <Step2MissionCardTitle tag="h3">
                      Karta Samodzielniaka
                    </Step2MissionCardTitle>
                    <CardText>
                      <Step2MissionList>
                        <li>
                          0{'\u00A0'}zł miesięcznie za{'\u00A0'}wydanie Karty
                          Samodzielniaka i{'\u00A0'}korzystanie z{'\u00A0'}niej
                        </li>
                        <li>
                          0{'\u00A0'}zł za{'\u00A0'}wypłaty gotówki Kartą
                          Samodzielniaka ze{'\u00A0'}wszystkich bankomatów w
                          {'\u00A0'}Polsce
                        </li>
                        <li>Brak możliwości płacenia w{'\u00A0'}internecie</li>
                      </Step2MissionList>
                    </CardText>
                  </Step2MissionCardBody>
                  <Step2MissionCardImageWrapper>
                    <NonceImg
                      alt="Karta Samodzielniaka"
                      fluid={data.step2MissionImage1.childImageSharp.fluid}
                      className="card-img"
                    />
                  </Step2MissionCardImageWrapper>
                </Step2MissionCard>
                <Step2MissionCardPay bg={step2MissionPayCard1} left />
              </Step2MissionCardItem>
              <Step2MissionCardItem
                xs={12}
                md={6}
                show={(showNumberCard === 2).toString()}
              >
                <Step2MissionCard>
                  <Step2MissionCardBody>
                    <Step2MissionCardIcon bg={step2MissionCard2Icon} />
                    <MobileCardsSwicher />
                    <Step2MissionCardTitle tag="h3">
                      Mikrokarta Samodzielniaka z&nbsp;wygodną opaską
                    </Step2MissionCardTitle>
                    <CardText>
                      <Step2MissionList>
                        <li>
                          Mikrokartę Samodzielniaka wraz z{'\u00A0'}opaską
                          otrzymasz w{'\u00A0'}przesyłce z{'\u00A0'}banku.
                          Możesz ją{'\u00A0'}umieścić w{'\u00A0'}opasce
                        </li>
                        <li>
                          1{'\u00A0'}zł miesięcznie za{'\u00A0'}wydanie
                          Mikrokarty Samodzielniaka i{'\u00A0'}korzystanie z
                          {'\u00A0'}niej
                        </li>
                        <li>
                          0{'\u00A0'}zł za{'\u00A0'}wypłaty gotówki Mikrokartą
                          Samodzielniaka ze{'\u00A0'}wszystkich bankomatów w
                          {'\u00A0'}Polsce
                        </li>
                        <li>Brak możliwości płacenia w{'\u00A0'}internecie</li>
                      </Step2MissionList>
                    </CardText>
                  </Step2MissionCardBody>
                  <Step2MissionCardImageWrapper>
                    <NonceImg
                      alt="Mikrokarta Samodzielniaka z wygodną opaską"
                      fluid={data.step2MissionImage2.childImageSharp.fluid}
                      className="card-img"
                    />
                  </Step2MissionCardImageWrapper>
                </Step2MissionCard>
                <Step2MissionCardPay bg={step2MissionPayCard2} right />
              </Step2MissionCardItem>

              {false && (
                <Step2MissionCardItem
                  xs={12}
                  md={4}
                  show={(showNumberCard === 3).toString()}
                >
                  <Step2MissionCard>
                    <CardBody>
                      <Step2MissionCardIcon bg={step2MissionCard3Icon} />
                      <MobileCardsSwicher />
                      <Step2MissionCardTitle tag="h3">
                        Mikrokartę Samodzielniaka z{'\u00A0'}wielofunkcyjnym
                        zegarkiem
                      </Step2MissionCardTitle>
                      <CardText>
                        <Step2MissionList>
                          <li>
                            Zegarek możesz otrzymać z{'\u00A0'}banku po
                            {'\u00A0'}
                            wzięciu udziału w{'\u00A0'}promocji „Megazegarek dla
                            Samodzielniaka - edycja 3”<sup>8</sup>
                          </li>
                          <li>
                            10{'\u00A0'}zł miesięcznie za{'\u00A0'}obsługę
                            Mikrokarty wydanej w{'\u00A0'}ramach promocji - do
                            {'\u00A0'}31.07.2023 r.
                            <br />
                            Po tym terminie opłata za{'\u00A0'}obsługę zgodna ze
                            {'\u00A0'}stawką wskazaną w{'\u00A0'}Taryfie Opłat i
                            {'\u00A0'}Prowizji<sup>9</sup>
                          </li>
                          <li>
                            0{'\u00A0'}zł za{'\u00A0'}wypłaty gotówki Mikrokartą
                            ze{'\u00A0'}wszystkich bankomatów w{'\u00A0'}Polsce
                          </li>
                          <li>
                            Brak możliwości płacenia w{'\u00A0'}internecie
                          </li>
                        </Step2MissionList>
                      </CardText>
                    </CardBody>
                    <NonceImg
                      alt="Mikrokartę Samodzielniaka z wielofunkcyjnym zegarkiem"
                      fluid={data.step2MissionImage3.childImageSharp.fluid}
                      className="card-img"
                    />
                  </Step2MissionCard>
                  <Step2MissionCardPay bg={step2MissionPayCard3} />
                </Step2MissionCardItem>
              )}
            </Row>

            {false && (
              <Row>
                <Col>
                  <Step2WatchFeatures>
                    <h3>
                      Megazegarek dla Samodzielniaka
                      <br />z{'\u00A0'}lokalizacją i{'\u00A0'}ubezpieczeniem
                    </h3>
                    <ListColorDots>
                      <li>
                        Cenna wiedza o{'\u00A0'}aktualnej lokalizacji dziecka
                        dzięki precyzyjnym źródłom, takim jak GPS, GSM, Wi-Fi
                      </li>
                      <li>
                        Możliwość ustawienia stref, dzięki którym dowiesz się,
                        że
                        {'\u00A0'}dziecko wychodzi ze{'\u00A0'}szkoły i
                        {'\u00A0'}
                        wraca do domu
                      </li>
                      <li>
                        Możliwość stałego kontaktu telefonicznego z{'\u00A0'}
                        dzieckiem
                      </li>
                      <li>
                        Wezwanie pomocy przez dziecko w każdej sytuacji za
                        {'\u00A0'}pomocą jednego przycisku SOS w{'\u00A0'}ramach
                        bezpłatnej rocznej usługi &bdquo;Gdzie jest
                        dziecko&rdquo;
                      </li>
                    </ListColorDots>
                  </Step2WatchFeatures>
                </Col>
                <Step2MissionAttention xs={12}>
                  <Step2MissionAttentionTitle>
                    UWAGA:
                  </Step2MissionAttentionTitle>
                  <p>
                    Aby otrzymać zegarek, zachęcamy do udziału w{'\u00A0'}
                    promocji „Megazegarek dla Samodzielniaka - edycja 3”. Liczba
                    zegarków dostępnych w{'\u00A0'}
                    promocji jest ograniczona do{'\u00A0'}650 sztuk. Szczegółowe
                    informacje o{'\u00A0'}promocji znajdziesz w{'\u00A0'}
                    regulaminie „Megazegarek dla Samodzielniaka - edycja 3”
                    dostępnym na stronach{' '}
                    <a
                      href="https://edycja3.kontosamodzielniaka.pl"
                      target="_blank"
                      rel="noreferrer"
                    >
                      edycja3.kontosamodzielniaka.pl
                    </a>{' '}
                    oraz{' '}
                    <a
                      href="https://www.bnpparibas.pl/klienci-indywidualni/konta/konto-karty-samodzielniaka"
                      target="_blank"
                      rel="noreferrer"
                    >
                      bnpparibas.pl
                    </a>
                    .
                  </p>
                </Step2MissionAttention>
              </Row>
            )}
          </Step2MissionColDesc>
        </Row>

        <Row>
          <Step2MissionNote>
            <Step2MissionNoteWrapper>
              <Step2MissionNoteTitle>
                Niezależnie od wybranego rozwiązania:
              </Step2MissionNoteTitle>
              <Step2MissionList>
                <li>
                  Dzienne limity Karty Samodzielniaka i Mikrokarty
                  Samodzielniaka wynoszą:
                  <li>transakcje zbliżeniowe bezgotówkowe – do 30 zł,</li>
                  <li>transakcje bezgotówkowe (z PIN-em) – do 100 zł,</li>
                  <li>transakcje w{'\u00A0'}bankomatach do 50 zł.</li>
                </li>
                <li>
                  W każdej chwili możesz zmniejszyć limit transakcji oraz
                  wyłączyć możliwość wykonania transakcji zbliżeniowych.
                </li>
              </Step2MissionList>
            </Step2MissionNoteWrapper>
          </Step2MissionNote>
          <Step2MissionNoteButtonWrapper xs={12}>
            <Step2MissionNoteButton
              as="a"
              href="https://www.bnpparibas.pl/kontakt/oddzialy-z-obsluga-detaliczna-i-biznesowa"
              target="_blank"
              className="btn"
            >
              <span>Umów wizytę</span>
            </Step2MissionNoteButton>
          </Step2MissionNoteButtonWrapper>
        </Row>
      </Container>
    </Step2MissionWrapper>
  )
}

export default Step2Mission
