import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
} from 'reactstrap'

import { Button, ListColorDots, StageTitle } from '../elements'

import stepNoteMissionCardImage from '../../images/mission/step_note_mission_card.svg'
import stepNoteMissionCollapsBttonBG from '../../images/mission/step_note_mission_collaps_btton_bg.svg'

const StepNoteMissionWrapper = styled.div`
  padding-bottom: 80px;
  margin-bottom: 20px;
  @media (max-width: 575px) {
    padding-bottom: 30px;
  }
`

const StepNoteMissionTopWrapper = styled.div`
  background-color: ${(props) => props.theme.colorGreenLight};
  padding-bottom: 170px;
  @media (max-width: 575px) {
    ${(props) =>
    !props.show &&
    `
      padding-bottom: 30px;
    `}
  }
`

const StepNoteMissionBottomWrapper = styled.div`
  margin-top: -170px;
  @media (max-width: 575px) {
    display: none;
    margin-top: -185px;
    ${(props) =>
    props.show &&
    `
      display: block;
    `}
  }
`

const StepNoteMissionColHeader = styled(Col)`
  text-align: center;
  padding-top: 80px;
  padding-bottom: 20px;
  margin-bottom: -55px;
  @media (max-width: 575px) {
    padding-top: 30px;
  }
`

const StepNoteMissionColImage = styled(Col)`
  height: 280px;
  background-image: url(${stepNoteMissionCardImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

const StepNoteMissionTitle = styled.h2`
  color: ${(props) => props.theme.colorWhite};
  font-weight: 400;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 26px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
  }
`

const StepNoteMissionSubTitle = styled.p`
  color: ${(props) => props.theme.colorWhite};
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 0;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size: 15px;
  }
`

const StepNoteMissionCardItem = styled(Col)`
  position: relative;
  @media (max-width: 991px) {
    ${(props) =>
    props.first &&
    `
      margin-bottom: 30px;
    `}
  }
`

const StepNoteMissionCard = styled(Card)`
  display: flex;
  height: 100%;
  align-items: flex-start;
  background-color: ${(props) => props.theme.colorGrayLight};
  padding-left: 25px;
  padding-bottom: 80px;
  border: none;
  box-shadow: 0px 12px 25px 0px rgba(24, 11, 11, 0.25);
  @media (max-width: 575px) {
    padding-left: 0;
    padding-bottom: 0;
  }
`

const StepNoteMissionCardStageTitle = styled(StageTitle)`
  margin-left: -45px;
  @media (max-width: 575px) {
    margin-left: -20px;
  }
`

const StepNoteMissionCardTitle = styled(CardTitle)`
  color: ${(props) => props.theme.colorGreenLight};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 50px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 30px;
  @media (max-width: 575px) {
    font-size: 40px;
  }
`
const StepNoteMissionList = styled(ListColorDots)``

const StepNoteMissionButtonWrapper = styled(Col)`
  text-align: center;
  margin-top: -65px;
  @media (max-width: 575px) {
    display: none;
  }
`

const StepNoteMissionButton = styled(Button)`
  max-width: 400px;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  &:after {
    content: none;
  }
`

const StepNoteMissionButtonCollapse = styled.div`
  display: none;
  width: 40px;
  height: 40px;
  background-image: url(${stepNoteMissionCollapsBttonBG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: -20px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  @media (max-width: 575px) {
    display: block;
    ${(props) =>
    props.show &&
    `
      display: none;
    `}
  }
`

const StepNoteMission = (props) => {
  const [show, setShow] = useState(false)

  const changeShow = () => {
    setShow(!show)
  }

  return (
    <StepNoteMissionWrapper>
      <StepNoteMissionTopWrapper show={show}>
        <Container>
          <Row>
            <StepNoteMissionColHeader xs={12}>
              <StepNoteMissionTitle>
                Jeśli jesteś rodzicem dziecka w{'\u00A0'}wieku 0-18 lat,
              </StepNoteMissionTitle>
              <StepNoteMissionSubTitle>
                mamy dla niego Konto Samodzielniaka z{'\u00A0'}Kartą do
                {'\u00A0'}Dorosłości wydawaną dla dzieci w{'\u00A0'}wieku 13-18
                {'\u00A0'}lat
              </StepNoteMissionSubTitle>
            </StepNoteMissionColHeader>
            <StepNoteMissionColImage xs={12} />
          </Row>
          <Row>
            <Col xs={12}>
              <StepNoteMissionButtonCollapse
                show={show}
                onClick={() => changeShow()}
              />
            </Col>
          </Row>
        </Container>
      </StepNoteMissionTopWrapper>
      <StepNoteMissionBottomWrapper show={show}>
        <Container>
          <Row>
            <StepNoteMissionCardItem xs={12} lg={6} first>
              <StepNoteMissionCard>
                <CardBody>
                  <StepNoteMissionCardStageTitle>
                    Krok 1.
                  </StepNoteMissionCardStageTitle>
                  <StepNoteMissionCardTitle tag="h3">
                    Poznaj zalety Konta
                    <br /> Samodzielniaka<sup>3</sup>
                  </StepNoteMissionCardTitle>
                  <CardText>
                    <StepNoteMissionList>
                      <li>
                        0 zł miesięcznie za{'\u00A0'}otwarcie i{'\u00A0'}
                        prowadzenie konta
                      </li>
                      <li>
                        Wygodny dostęp do konta dzięki bankowości internetowej
                        GOonline i aplikacji mobilnej GOmobile<sup>3</sup>
                      </li>
                      <li>
                        Możliwość otwarcia w GOonline Lokaty dla Samodzielniaka:
                        <li>
                          oprocentowanie w{'\u00A0'}skali roku 4,5% dla lokat na
                          {'\u00A0'}3{'\u00A0'}miesiące
                        </li>
                        <li>dla kwot od 100 zł do 5000 zł</li>
                        <li>można otworzyć maksymalnie 2 lokaty</li>
                        <li>lokata odnawialna</li>
                        <li>
                          lokatę może otworzyć przedstawiciel ustawowy lub
                          dziecko w wieku 13-18 lat (za zgodą przedstawiciela
                          ustawowego) w systemie bankowości internetowej
                          GOonline
                        </li>
                      </li>
                    </StepNoteMissionList>
                  </CardText>
                </CardBody>
              </StepNoteMissionCard>
            </StepNoteMissionCardItem>
            <StepNoteMissionCardItem xs={12} lg={6}>
              <StepNoteMissionCard>
                <CardBody>
                  <StepNoteMissionCardStageTitle>
                    Krok 2.
                  </StepNoteMissionCardStageTitle>
                  <StepNoteMissionCardTitle tag="h3">
                    Zalety Karty
                    <br /> Do Dorosłości<sup>5</sup>
                  </StepNoteMissionCardTitle>
                  <CardText>
                    <StepNoteMissionList>
                      <li>
                        0 zł miesięcznie za{'\u00A0'}wydanie i{'\u00A0'}
                        użytkowanie karty
                      </li>
                      <li>
                        0 zł za wypłaty gotówki kartą ze{'\u00A0'}wszystkich
                        bankomatów w{'\u00A0'}Polsce
                      </li>
                      <li>
                        Możliwość płatności kartą w{'\u00A0'}internecie do
                        {'\u00A0'}ustalonego limitu
                      </li>
                      <li>
                        Dzienne limity wynoszą<sup>6</sup>:
                        <li>transakcje zbliżeniowe bezgotówkowe – do 100 zł</li>
                        <li>transakcje bezgotówkowe (z PIN-em) – do 5000 zł</li>
                        <li>transakcje internetowe – do 5000 zł</li>
                        <li>transakcje w bankomatach – do 5000 zł</li>
                      </li>
                      <li>
                        W każdej chwili rodzic lub opiekun prawny<sup>4</sup>{' '}
                        może zmniejszyć limit transakcji oraz uniemożliwić
                        wykonywanie transakcji zbliżeniowych
                      </li>
                    </StepNoteMissionList>
                  </CardText>
                </CardBody>
              </StepNoteMissionCard>
            </StepNoteMissionCardItem>
            <StepNoteMissionButtonWrapper xs={12}>
              <StepNoteMissionButton
                as="a"
                href="https://www.bnpparibas.pl/kontakt/oddzialy-z-obsluga-detaliczna-i-biznesowa"
                target="_blank"
                className="btn"
                id="mission-btn-3"
              >
                <span>Umów wizytę</span>
              </StepNoteMissionButton>
            </StepNoteMissionButtonWrapper>
          </Row>
        </Container>
      </StepNoteMissionBottomWrapper>
    </StepNoteMissionWrapper>
  )
}

export default StepNoteMission
