import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { Button, ListColorDots, StageTitle, StageSubTitle } from '../elements'
import { NonceImg } from '../img'

const Step1MissionWrapper = styled.div`
  position: relative;
  height: 100%;
  margin-top: -280px;
  margin-bottom: 20px;
  z-index: 10;
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: -220px;
  }
  @media (max-width: 575px) {
    margin-top: -240px;
  }
`

const Step1MissionColImage = styled(Col)`
  position: relative;
  /* display: flex; */
  align-items: center;
  padding-left: 0;
  @media (min-width: 576px) and (max-width: 991px) {
    padding-top: 50px;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 50px;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
  }
`

const Step1MissionImageDesktop = styled(NonceImg)`
  @media (max-width: 991px) {
    display: none;
  }
`

const Step1MissionImageMobile = styled(NonceImg)`
  display: none;
  @media (max-width: 991px) {
    display: block;
    margin-top: 60px;
    margin-bottom: 40px;
  }
`

const Step1MissionColDesc = styled(Col)`
  padding-top: 40px;
  @media (max-width: 991px) {
    order: -1;
  }
`

const Step1MissionStageTitleWrapper = styled.div`
  @media (max-width: 991px) {
    text-align: center;
  }
`

const Step1MissionStageTitle = styled(StageTitle)``

const Step1MissionSubTitleWrapper = styled.div`
  @media (max-width: 991px) {
    text-align: center;
  }
`

const Step1MissionSubTitle = styled(StageSubTitle)``

const Step1MissionList = styled(ListColorDots)``

const Step1MissionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: 575px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 991px) {
    text-align: center;
  }
`

const Step1MissionButton = styled(Button)`
  width: auto;
  margin-left: 0;
  margin-right: 0;
  padding-left: 20px;
  padding-right: 0;

  @media (min-width: 576px) and (max-width: 991px) {
    width: auto;
  }
  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
  }

  &:after {
    content: none;
  }

  &:hover {
    span {
      &:after {
        right: 15px;
      }
    }
  }

  span {
    padding-left: 0;
    padding-right: 60px;

    &:after {
      right: 20px;
    }
  }
`

const Step1Mission = () => {
  const data = useStaticQuery(graphql`
    query {
      missionStep1Image: file(
        relativePath: { eq: "mission/step1_mission.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      missionStep1MobileImage: file(
        relativePath: { eq: "mission/step1_mission_mobile.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 767) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Step1MissionWrapper>
      <Container>
        <Row>
          <Step1MissionColImage xs={12} lg={6}>
            <Step1MissionImageDesktop
              alt="Poznaj zalety Konta Karty Samodzielniaka"
              fluid={data.missionStep1Image.childImageSharp.fluid}
            />
            <Step1MissionImageMobile
              alt="Poznaj zalety Konta Karty Samodzielniaka"
              fluid={data.missionStep1MobileImage.childImageSharp.fluid}
            />
          </Step1MissionColImage>
          <Step1MissionColDesc xs={12} lg={6}>
            <Step1MissionStageTitleWrapper>
              <Step1MissionStageTitle>Krok 1</Step1MissionStageTitle>
            </Step1MissionStageTitleWrapper>
            <Step1MissionSubTitleWrapper>
              <Step1MissionSubTitle>
                Poznaj zalety Konta
                <br /> Karty Samodzielniaka<sup>1</sup>
              </Step1MissionSubTitle>
            </Step1MissionSubTitleWrapper>
            <Step1MissionList>
              <li>
                0{'\u00A0'}zł za{'\u00A0'}otwarcie i{'\u00A0'}prowadzenie konta
                miesięcznie<sup>1</sup>
              </li>
              <li>
                Wybór karty pomocnej w{'\u00A0'}samodzielnym bankowaniu Twojego
                dziecka<sup>2</sup>:
                <li>
                  <strong>
                    Karta Samodzielniaka (tradycyjna karta debetowa),
                  </strong>
                </li>
                <li>
                  <strong>Mikrokarta Samodzielniaka.</strong>
                </li>
              </li>
              <li>
                Informacja o{'\u00A0'}transakcjach dostępna w{'\u00A0'}
                bankowości internetowej GOonline lub w{'\u00A0'}aplikacji
                mobilnej GOmobile
              </li>
            </Step1MissionList>
            <Step1MissionButtonWrapper>
              <Step1MissionButton
                as="a"
                href="https://goonline.bnpparibas.pl/"
                target="_blank"
                className="btn"
                id="mission-btn-2"
              >
                <span>Otwórz online</span>
              </Step1MissionButton>
              <Step1MissionButton
                as="a"
                href="https://www.bnpparibas.pl/kontakt/oddzialy-z-obsluga-detaliczna-i-biznesowa"
                target="_blank"
                className="btn"
                id="mission-btn-1"
              >
                <span>Umów wizytę</span>
              </Step1MissionButton>
            </Step1MissionButtonWrapper>
          </Step1MissionColDesc>
        </Row>
      </Container>
    </Step1MissionWrapper>
  )
}

export default Step1Mission
