import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { ListColorDots, StageTitle, StageSubTitle } from '../elements'

import step3MissionClockBGImage from '../../images/mission/step3_mission_clock_bg.svg'

const Step3MissionWrapper = styled.div`
  background-color: ${(props) => props.theme.colorGrayLight};
  padding-top: 50px;
  padding-bottom: 80px;
`

const Step3MissionColHeader = styled(Col)`
  text-align: center;
  padding-bottom: 20px;
`

const Step3MissionColImage = styled(Col)`
  background-image: url(${step3MissionClockBGImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
`

const Step3MissionColDesc = styled(Col)`
  padding-top: 40px;
  padding-bottom: 40px;
  p {
    margin-top: 30px;
    margin-bottom: 40px;
  }
`

const Step3MissionStageTitle = styled(StageTitle)``

const Step3MissionSubTitle = styled(StageSubTitle)``

const Step3MissionList = styled(ListColorDots)``

const Step3Mission = (props) => {
  return (
    <Step3MissionWrapper>
      <Container>
        <Row>
          <Step3MissionColHeader xs={12}>
            <Step3MissionStageTitle>Krok 3.</Step3MissionStageTitle>
            <Step3MissionSubTitle>
              Zadbaj o{'\u00A0'}wyższy poziom bezpieczeństwa dziecka
              <br />z{'\u00A0'}ubezpieczeniem „Bezpieczny Samodzielniak”
            </Step3MissionSubTitle>
          </Step3MissionColHeader>
          <Step3MissionColImage md={4} />
          <Step3MissionColDesc md={8}>
            <Row>
              <Col xs={12}>
                <p>
                  <strong>
                    Korzystając z promocji „Megazegarek dla Samodzielniaka -
                    edycja 3”, możesz skorzystać z{'\u00A0'}pakietu usług
                    ubezpieczeniowych dla Twojego dziecka, w{'\u00A0'}zakres
                    których wchodzą m.in.:
                  </strong>
                </p>
              </Col>
              <Col md={6}>
                <Step3MissionList>
                  <li>wizyta lekarza</li>
                  <li>telekonsultacja z{'\u00A0'}lekarzem</li>
                  <li>dostawa leków</li>
                  <li>wizyta pielęgniarki</li>
                </Step3MissionList>
              </Col>
              <Col md={6}>
                <Step3MissionList>
                  <li>opieka domowa nad dzieckiem</li>
                  <li>korepetycje (również online)</li>
                  <li>
                    cyber mobbing / wsparcie w ochronie reputacji internetowej
                  </li>
                  <li>psycholog</li>
                </Step3MissionList>
              </Col>
            </Row>
          </Step3MissionColDesc>
        </Row>
      </Container>
    </Step3MissionWrapper>
  )
}

export default Step3Mission
