import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import IntroMission from '../components/mission/intro-mission'
import Step1Mission from '../components/mission/step1-mission'
import Step2Mission from '../components/mission/step2-mission'
import Step3Mission from '../components/mission/step3-mission'
import StepNoteMission from '../components/mission/step-note-mission'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Ekwipunek Samodzielniaka" />

    <IntroMission />
    <Step1Mission />
    <Step2Mission />
    {false && <Step3Mission />}
    <StepNoteMission />
  </Layout>
)

export default IndexPage
